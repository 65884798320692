<template>
    <!-- 影像服务详情路由 -->
    <div class="field_order_box">
        <div class="container">
            <!-- 面包屑导航 -->
            <div class="breadcrumb_box">
                <bread-crumb :breadcrumb="breadcrumb"></bread-crumb>
            </div>
            <!-- 商品详情 -->
            <field-order-form></field-order-form>
        </div>
    </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'

export default {
    data() {
        return {
            // 面包屑导航数据
            breadcrumb: [
                {
                    path: "/field",
                    path_name: "场地租赁",
                },
                {
                    path: "detail",
                    path_name: "详情",
                },
            ],
        }
    },
    components:{
        breadCrumb: ()=> import('@/components/breadCrumb'),
        fieldOrderForm: ()=> import('@/components/fieldOrderForm'),
    },
    methods: {
        ...mapMutations([
            "clearInfo",
        ]),
        ...mapActions([
            "getFieldDetail",
        ]),
    },
    beforeRouteEnter (to, from, next) {
        // ...
        next(vm => {
            vm.getFieldDetail(to.query.id);
        });
    },
    beforeRouteLeave (to, from, next) {
        this.clearInfo();
        next();
    },
}
</script>

<style scoped>
/* 相机租赁详情页盒子 */
.field_order_box{
    background-color: #f5f5f5;
}
.container{
	
}
/* 相机租赁面包屑导航盒子 */
.breadcrumb_box{
    margin: 20px 0;
}
</style>
